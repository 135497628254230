import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/:mid',
    name: 'home',
    component: ()=>import('@/views/HomeView.vue')
  },
  {
    path: '/:mid/menu/delivery',
    name: 'home',
    component: ()=>import('@/views/HomeView.vue')
  },
  {
    path: '/:mid/menu/launch',
    name: 'MenuLaunch',
    component: ()=> import('@/views/menu/MenuLaunch')
  },
  {
    path: '/:mid/menu/dinner',
    name: 'MenuDinner',
    component: ()=> import('@/views/menu/MenuDinner')
  },
  {
    path: '/:mid/checkout',
    name: 'Checkout',
    component: ()=>import('@/views/CheckoutView.vue'),
    meta:{
      requiredAuth:true
    }
  },
  {
    path: '/:mid/login',
    name: 'Login',
    component: ()=>import('@/views/Login.vue')
  },
  {
    path: '/:mid/register',
    name: 'Regiter',
    component: ()=>import('@/views/Register.vue')
  },
  {
    path: '/:mid/resetpassword',
    name: 'ResetPassword',
    component: ()=>import('@/views/ResetPassword.vue') 
  },
  {
    path: '/:mid/profile',
    name: 'profile',
    component: ()=>import('@/views/ProfileView/index.vue'),
    redirect: '/:mid/profile/dashboard',
    children:[
      {
        path:'dashboard',
        name:'Dashboard',
        component:()=>import('@/views/ProfileView/DashboardView.vue')
      },
      {
        path:'orders',
        name:'Orders',
        component:()=>import('@/views/ProfileView/Orders.vue'),
        meta:{
          requiredAuth:true
        }
      },
      {
        path:'restaurant',
        name:'Restaurant',
        component: ()=>import('@/views/ProfileView/Restaurant.vue')
      },
      {
        path:'privacy',
        name:'Privacy',
        component:()=>import('@/views/ProfileView/Privacy.vue')
      }
    ],
  },
  {
    path: '/:mid/payment',
    name: 'Stripe',
    component: ()=> import('@/views/StripePay/index')
  },
  {
    path: '/:mid/success',
    name: 'PaySuccess',
    component: ()=> import('@/views/StripePay/success')
  },
  {
    path: '/:mid/order/:number',
    name: 'OrderDetail',
    component: ()=> import('@/views/order/detail')
  }
]


const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requireAuth)){
    if (to.path === '/'+to.params.mid+'/login') {
      next();
    } else {
      let user = localStorage.getItem('dXNlcg==');
  
      if (user === null || user === '') {
        next('/'+to.params.mid+'/login');
      } else {
        next();
      }
    }
   }else {
        //如果不需要登录认证，就直接访问
        next()
    }
});

export default router
