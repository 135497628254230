import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import Vant from 'vant';
import 'vant/lib/index.css';

import Cube from 'cube-ui'

import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.use(Cube)
Vue.use(Vant)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
