import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart:[],
    showCart: false,
    serviceChoice:'Consegna a domicilio',
    shippingFee: (0).toFixed(2),
    showAddressAddForm:false,
    address: {
      street: '',
      number: '',
      intercom: '',
      zip_code: '',
      city: ''
     }
  },
  getters: {
    subTotalAmount(state){
        return (state.cart.reduce((sum, dishes)=>{
          if(dishes.is_menu == 2){
              return sum + dishes.quantity * dishes.price
          }else{
              return sum
          }
      }, 0).toFixed(2))
    },
    totalDishes(state){
        return (state.cart.reduce((sum, dishes)=>{
         
              return sum + dishes.quantity
         
      }, 0))
    },
    totalPriceMenuFixed(state){
      return (state.cart.reduce((sum, dishes)=>{
        if(dishes.is_menu == 1){
            return sum + parseFloat(dishes.price)
        }else{
            return sum
        }
    }, 0).toFixed(2))
    }
  },
  mutations: {
    
  },
  actions: {
  },
  modules: {
  }
})
