<template>
  <div class="main-container">
      <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  
  data: () => ({
    //
  }),
};
</script>
<style>
html, body{
  height: 100%;
}
</style>

